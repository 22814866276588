import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Drawer, MenuItem, IconButton, Typography, Divider, Hidden} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';

const drawerWidth = 240;

const styles = theme => ({
  hide: {
    display: 'none'
  },
  drawerPaper: {
    background:theme.palette.grey[900],
    borderRightColor: theme.palette.grey[700],
    color:'#fff',
    position: 'relative',
    minHeight: '100%',
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '&::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      borderRadius: '100px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0,0,0,0.2)',
      borderRadius: '100px'
    },
    '&::-webkit-scrollbar-thumb:active': {
        background: 'rgba(0,0,0,0.6)',
        borderRadius: '100px'
    },
    '&::-webkit-scrollbar-thumb:vertical': {
      minHeight: '10px'
    },
    '&::-webkit-scrollbar-thumb:horizontal': {
      minWidth: '10px'
    }
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth
  },
  drawerHeader: {
    background: theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 15px',
    height: 56,
  },
  drawerHeaderTitle: {
    flex: 1,
    color: theme.palette.common.white,
  },
  menuUl:{
    margin:0,
    padding:0
  },
  menuSelected: {
    background: theme.palette.common.faintBlack,
  },
  menuLink: {
    color: theme.palette.primary[700],
    textDecoration: 'none',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  subMenu:{
    background: theme.palette.common.faintBlack,
    '& $icon': {
      background: 'none',
      color: theme.palette.common.white
    }
  },
  icon: {
    marginRight: 10,
    fontSize: '1.25rem',
    width: '2rem',
    height: '2rem',
    lineHeight: '2rem',
    textAlign: 'center',
    verticalAlign: 'middle',
    borderRadius: '100%',
    background: theme.palette.common.white
  },
  expand: {
    color: theme.palette.common.white,
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  textFlex: {
    flex: 1,
    color: theme.palette.common.darkWhite,
    fontSize: '0.875rem'
  },
  [theme.breakpoints.up('sm')]: {
    drawerHeader: {
      height: 64
    }
  }
});

const menu = [{
  url: '/dashboard/',
  title: 'Bảng điều khiển',
  icon: 'dashboard'
}, {
  url: '/album/',
  title: 'Album Hình',
  icon: 'photo_album'
},
// {
//   url: '/products/',
//   title: 'Sản phẩm',
//   icon: 'work_outline',
//   subs: [
//     {url:'/category/',title:'Nhóm sản phẩm',icon:'kitchen'},
//     {url:'/product/',title:'Sản phẩm',icon:'card_travel'},
//     {url: '/property/',title: 'Thuộc tính sản phẩm',icon: 'line_style'}
// ]},
{
  url: '/training/',
  title: 'Đào tạo',
  icon: 'school',
  subs: [
    {url:'/course-offline/',title:'Khóa học (offline)',icon:'book'},
    {url:'/course-offline-category/',title:'Nhóm khóa học (offline)',icon:'bookmarks'},
    {url:'/course-online/',title:'Khóa học (online)',icon:'book'},
    {url:'/course-online-category/',title:'Nhóm khóa học (online)',icon:'bookmarks'},
    {url:'/calendar/',title:'Lịch khai giảng/lớp học',icon:'calendar_today'},
    {url:'/lecturers/',title:'Giảng viên',icon:'contacts'},
    // {url:'/students/',title:'Học viên',icon:'contacts'},
    {url:'/projects/',title:'Projects',icon:'assignment'},
    {url:'/document/',title:'Tài liệu',icon:'notes'},
]}, {
  url: '/service/',
  title: 'Dịch vụ',
  icon: 'star',
  subs: [
    {url:'/service/',title:'Dịch vụ',icon:'star'},
    {url:'/booking/',title:'Lịch đặt dịch vụ',icon:'calendar_view_day'}
]}, {
  url: '/bill/',
  title: 'Hóa đơn',
  icon: 'store'
}, {
  url: '/account/',
  title: 'Tài khoản - Khách hàng',
  icon: 'face'
},/*  {
  url: '/report/',
  title: 'Báo cáo',
  icon: 'assessment'
}, */
{
  url: '/contents/',
  title: 'Nội dung',
  icon: 'note_add',
  subs: [
    // {url: '/templates/',title: 'Templates',icon: 'description'},
    {url: '/shareblocks/',title: 'Nội dung chia sẻ',icon: 'screen_share'},
    {url: '/pages/',title: 'Trang web',icon: 'note_add'},
    {url: '/banner/',title: 'Biểu ngữ',icon: 'photo'},
    {url:'/menu/',title:'Thanh điều hướng',icon:'menu'},
    {url: '/testimonial/',title: 'Chứng thực',icon: 'record_voice_over'}
]}, {
  url: '/news/',
  title: 'Tin tức',
  icon: 'language',
  subs: [
    {url: '/news/',title: 'Tin tức',icon: 'language'},
    {url:'/newscat/',title:'Nhóm tin tức',icon:'kitchen'},
    /* {url: '/comments/',title: 'Thảo luận',icon: 'comment'} */
]}, {
  url: '/blog/',
  title: 'Blog',
  icon: 'description',
  subs: [
    {url: '/blog/',title: 'Blog',icon: 'description'},
    {url:'/blogcat/',title:'Nhóm',icon:'subject'},
]}, {
  url: '/hiring/',
  title: 'Tuyển dụng',
  icon: 'account_box',
  subs: [
    {url: '/hiring/',title: 'Tin tuyển dụng',icon: 'perm_contact_calendar'},
    {url:'/career/',title:'Ngành nghề',icon:'business_center'},
    {url:'/candidate/',title:'Ứng viên',icon:'face'},
    {url: '/company/',title: 'Công ty',icon: 'account_balance'}
]}, {
  url: '/faq/',
  title: 'FAQ',
  icon: 'question_answer',
},{
  url: '/controls/',
  title: 'Quản lý',
  icon: 'card_travel',
  subs: [
    {url: '/users/',title: 'Tài khoản',icon: 'account_circle'},
    {url:'/group/',title:'Nhóm tài khoản',icon:'group'},
    {url: '/subscribers/',title: 'Subscribers',icon: 'record_voice_over'},
    {url: '/campaign/',title: 'Campaign',icon: 'local_post_office'},
    {url: '/permission/',title: 'Phân quyền',icon: 'warning'}
]}, {
  url: '/media/',
  title: 'Quản lý tập tin',
  icon: 'perm_media'
}, {
  url: '/setting/',
  title: 'Cấu hình',
  icon: 'settings'
}];

class ReactPressMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      expanded: menu.map((item,index)=>{
        if(item.subs!==undefined){
          return false;
        }
      })
    }
  }
  handleExpandClick(event,index) {
    event.preventDefault();
    let arr = this.state.expanded;
    arr[index] = !arr[index];
    this.setState({ selectedIndex: index,expanded: arr },function(){

    });
    //this.props.setTitle(title);
  }
  componentDidMount() {
    var p = this.context.router.route.location.pathname,
        path = p.split('/')[1];
    this.changeMenuStatus(path);
  }
  changeMenuStatus(path){
    var page = path,
        $this = this;
    //console.log(page);
    switch (page) {
      case 'dashboard':
        this.setState({selectedIndex: 0,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      case 'album':
        this.setState({selectedIndex: 1,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      // case 'product':
      // case 'category':
      // case 'property':
      //   this.setState({selectedIndex: 3,expanded:menu.map((item,index)=>{
      //     if(index==2){
      //       return true;
      //     }
      //   })});
      //   break;
      case 'course-offline':
      case 'course-offline-category':
      case 'course-online':
      case 'course-online-category':
      case 'document':
      case 'calendar':
      case 'lecturers':
      case 'students':
        this.setState({selectedIndex: 2,expanded:menu.map((item,index)=>{
          if(index===2){
            return true;
          }
        })});
        break;
      case 'service':
      case 'booking':
        this.setState({selectedIndex: 3,expanded:menu.map((item,index)=>{
          if(index===3){
            return true;
          }
        })});
        break;
      case 'bill':
        this.setState({selectedIndex: 4,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      case 'account':
        this.setState({selectedIndex: 5,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false
          }
        })});
        break;
      /* case 'report':
        this.setState({selectedIndex: 5,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break; */
      case 'shareblocks':
      case 'banner':
      case 'menu':
      case 'pages':
      case 'testimonial':
        this.setState({
          selectedIndex: 6,
          expanded:menu.map((item,index)=>{
            if(index===6){
              return true;
            }
          })
        });
        break;
      case 'news':
      case 'newscat':
        this.setState({selectedIndex: 7,expanded: menu.map((item,index)=>{
          if(index===7){
            return true;
          }
        })},function(){
         // console.log($this.state);
        });
        break;
      case 'blog':
      case 'blogcat':
        this.setState({selectedIndex: 8,expanded: menu.map((item,index)=>{
          if(index===8){
            return true;
          }
        })},function(){
          // console.log($this.state);
        });
        break;
      case 'hiring':
      case 'career':
      case 'candidate':
      case 'company':
        this.setState({selectedIndex: 9,expanded: menu.map((item,index)=>{
          if(index===9){
            return true;
          }
        })},function(){
          // console.log($this.state);
        });
        break;
      case 'faq':
      case 'faqgroup':
        this.setState({selectedIndex: 10,expanded: menu.map((item,index)=>{
          if(index===10){
            return true;
          }
        })});
        break;
      case 'users':
      case 'group':
      case 'subscribers':
      case 'campaign':
        this.setState({selectedIndex: 11,expanded:menu.map((item,index)=>{
          if(index===11){
            return true;
          }
        })});
        break;
      case 'media':
        this.setState({selectedIndex: 12,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      case 'setting':
        this.setState({selectedIndex: 13,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
        break;
      default:
        this.setState({selectedIndex: 0,expanded: menu.map((item,index)=>{
          if(item.subs!==undefined){
            return false;
          }
        })});
    }
  }
  handleClose() {
    this.props.closeMenu();
  }
  setTitle(event,target, title, index) {
    if(this.state.selectedIndex===index){
      event.preventDefault();
      return;
    }
    if(index!==null)this.setState({selectedIndex: index});
    this.props.setTitle(title);
    this.changeMenuStatus(target.split('/')[1]);
  }
  render() {
    const classes = this.props.classes;
    //var type = this.props.screenWidth < 960 ? 'persistent' : 'permanent';

    return (
      <Drawer variant='persistent' open={this.props.open} classes={{
        paper: classNames(classes.drawerPaper, !this.props.isLoggedIn && classes.hide)
      }}>
        <div className={classes.drawerInner}>
          <div className={classes.drawerHeader}>
            <Typography className={classes.drawerHeaderTitle} type='title'>ReactPress v1.0.0</Typography>
            <IconButton onClick={() => this.handleClose()} color="primary" className={classNames('btn-close-menu')}><ChevronLeftIcon/></IconButton>
          </div>
          <Divider/>
          <ul className='menuUl'>
          {menu.map((item, index) => (
            <MenuItem key={index} selected={index === this.state.selectedIndex} classes={{ selected: classes.menuSelected }}>
              {item.subs!==undefined?(
                <div className={classes.menuWrapper}>
                  <Link className={classes.menuLink} to={item.url} onClick={(event)=>this.handleExpandClick(event,index)}>
                    <Icon className={classes.icon}>{item.icon}</Icon>
                    <Typography className={classes.textFlex}>{item.title}</Typography>
                    <ExpandMoreIcon className={classNames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded[index],
                    })} />
                  </Link>
                  <Collapse in={this.state.expanded[index]} timeout="auto" unmountOnExit className={classes.subMenu}>
                  {
                    item.subs.map((n,i)=>(
                      <Link className={classes.menuLink} to={n.url} key={'sub_'+i} onClick={event => this.setTitle(event,n.url,n.title, null)}>
                        <Icon className={classes.icon}>{n.icon}</Icon>
                        <Typography className={classes.textFlex}>{n.title}</Typography>
                      </Link>
                    ))
                  }
                  </Collapse>
                </div>
              ):(
                <Link className={classes.menuLink} to={item.url} onClick={event => this.setTitle(event,item.url,item.title, index)}>
                <Icon className={classes.icon}>{item.icon}</Icon>
                <Typography variant='body2' className={classes.textFlex}>{item.title}</Typography>
                </Link>
              )}
            </MenuItem>
          ))}
          </ul>
        </div>
      </Drawer>
    );
  }
}

ReactPressMenu.propTypes = {
  classes: PropTypes.object.isRequired
};
ReactPressMenu.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(ReactPressMenu);
