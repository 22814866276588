import { createMuiTheme } from '@material-ui/core/styles';
// const primaryColor = "#9c27b0";
// const warningColor = "#ff9800";
// const successColor = "#4caf50";
// const infoColor = "#00acc1";
// const roseColor = "#e91e63";

const theme = createMuiTheme({
  "direction": "ltr",
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff",
      "transparent": "rgba(0, 0, 0, 0)",
      "fullBlack": "rgba(0, 0, 0, 1)",
      "darkBlack": "rgba(0, 0, 0, 0.87)",
      "lightBlack": "rgba(0, 0, 0, 0.54)",
      "minBlack": "rgba(0, 0, 0, 0.26)",
      "faintBlack": "rgba(0, 0, 0, 0.12)",
      "fullWhite": "rgba(255, 255, 255, 1)",
      "darkWhite": "rgba(255, 255, 255, 0.87)",
      "lightWhite": "rgba(255, 255, 255, 0.54)",
    },
    "type": "light",
    "primary": {
      "50": "#E3F2FD",
      "100": "#BBDEFB",
      "200": "#90CAF9",
      "300": "#64B5F6",
      "400": "#42A5F5",
      "500": '#2196F3',
      "600": "#1E88E5",
      "700": "#1976D2",
      "800": "#1565C0",
      "900": "#0D47A1",
      "A100": "#82B1FF",
      "A200": "#448AFF",
      "A400": "#2979FF",
      "A700": "#2962FF",
      "contrastDefaultColor": "light"
    },
    "secondary": {
      "50": "#FFEBEE",
      "100": "#FFCDD2",
      "200": "#EF9A9A",
      "300": "#E57373",
      "400": "#EF5350",
      "500": "#F44336",
      "600": "#E53935",
      "700": "#D32F2F",
      "800": "#C62828",
      "900": "#B71C1C",
      "A100": "#FF8A80",
      "A200": "#FF5252",
      "A400": "#FF1744",
      "A700": "#D50000",
      "contrastDefaultColor": "light"
    },
    "error": {
      "50": "#FBE9E7",
      "100": "#FFCCBC",
      "200": "#FFAB91",
      "300": "#FF8A65",
      "400": "#FF7043",
      "500": "#FF5722",
      "600": "#F4511E",
      "700": "#E64A19",
      "800": "#D84315",
      "900": "#BF360C",
      "A100": "#FF9E80",
      "A200": "#FF6E40",
      "A400": "#FF3D00",
      "A700": "#DD2C00",
      "contrastDefaultColor": "light"
    },
    "grey": {
      "50": "#ECEFF1",
      "100": "#CFD8DC",
      "200": "#B0BEC5",
      "300": "#90A4AE",
      "400": "#78909C",
      "500": "#607D8B",
      "600": "#546E7A",
      "700": "#455A64",
      "800": "#37474F",
      "900": "#263238",
      "A100": "#d5d5d5",
      "A200": "#aaaaaa",
      "A400": "#303030",
      "A700": "#616161",
      "contrastDefaultColor": "dark"
    },
    "shades": {
      "dark": {
        "text": {
          "primary": "rgba(255, 255, 255, 1)",
          "secondary": "rgba(255, 255, 255, 0.7)",
          "disabled": "rgba(255, 255, 255, 0.5)",
          "hint": "rgba(255, 255, 255, 0.5)",
          "icon": "rgba(255, 255, 255, 0.5)",
          "divider": "rgba(255, 255, 255, 0.12)",
          "lightDivider": "rgba(255, 255, 255, 0.075)"
        },
        "input": {
          "bottomLine": "rgba(255, 255, 255, 0.7)",
          "helperText": "rgba(255, 255, 255, 0.7)",
          "labelText": "rgba(255, 255, 255, 0.7)",
          "inputText": "rgba(255, 255, 255, 1)",
          "disabled": "rgba(255, 255, 255, 0.5)"
        },
        "action": {
          "active": "rgba(255, 255, 255, 1)",
          "disabled": "rgba(255, 255, 255, 0.3)"
        },
        "background": {
          "default": "#303030",
          "paper": "#424242",
          "appBar": "#212121",
          "contentFrame": "#212121",
          "status": "#000"
        }
      },
      "light": {
        "text": {
          "primary": "rgba(0, 0, 0, 0.87)",
          "secondary": "rgba(0, 0, 0, 0.54)",
          "disabled": "rgba(0, 0, 0, 0.38)",
          "hint": "rgba(0, 0, 0, 0.38)",
          "icon": "rgba(0, 0, 0, 0.38)",
          "divider": "rgba(0, 0, 0, 0.12)",
          "lightDivider": "rgba(0, 0, 0, 0.075)"
        },
        "input": {
          "bottomLine": "rgba(0, 0, 0, 0.42)",
          "helperText": "rgba(0, 0, 0, 0.54)",
          "labelText": "rgba(0, 0, 0, 0.54)",
          "inputText": "rgba(0, 0, 0, 0.87)",
          "disabled": "rgba(0, 0, 0, 0.42)"
        },
        "action": {
          "active": "rgba(0, 0, 0, 0.54)",
          "disabled": "rgba(0, 0, 0, 0.26)"
        },
        "background": {
          "default": "#fafafa",
          "paper": "#fff",
          "appBar": "#f5f5f5",
          "contentFrame": "#eeeeee"
        }
      }
    },
    "text": {
      "primary": "rgba(0, 0, 0, 0.87)",
      "secondary": "rgba(0, 0, 0, 0.54)",
      "disabled": "rgba(0, 0, 0, 0.38)",
      "hint": "rgba(0, 0, 0, 0.38)",
      "icon": "rgba(0, 0, 0, 0.38)",
      "divider": "rgba(0, 0, 0, 0.12)",
      "lightDivider": "rgba(0, 0, 0, 0.075)"
    },
    "input": {
      "bottomLine": "rgba(0, 0, 0, 0.42)",
      "helperText": "rgba(0, 0, 0, 0.54)",
      "labelText": "rgba(0, 0, 0, 0.54)",
      "inputText": "rgba(0, 0, 0, 0.87)",
      "disabled": "rgba(0, 0, 0, 0.42)"
    },
    "action": {
      "active": "rgba(0, 0, 0, 0.54)",
      "disabled": "rgba(0, 0, 0, 0.26)"
    },
    "background": {
      "default": "#fafafa",
      "paper": "#fff",
      "appBar": "#f5f5f5",
      "contentFrame": "#eeeeee"
    }
  },
  "typography": {
    "useNextVariants": true,
    "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "h1": {
      "fontSize": 112,
      "fontWeight": 300,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "letterSpacing": "-.04em",
      "lineHeight": 1,
      "color": "rgba(0, 0, 0, 0.54)"
    },
    "h2": {
      "fontSize": 56,
      "fontWeight": 400,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "letterSpacing": "-.02em",
      "lineHeight": 1.35,
      "color": "rgba(0, 0, 0, 0.54)"
    },
    "h3": {
      "fontSize": 45,
      "fontWeight": 400,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": "48px",
      "color": "rgba(0, 0, 0, 0.54)"
    },
    "h4": {
      "fontSize": 34,
      "fontWeight": 400,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": "40px",
      "color": "rgba(0, 0, 0, 0.54)"
    },
    "h5": {
      "fontSize": 24,
      "fontWeight": 400,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": "32px",
      "color": "rgba(0, 0, 0, 0.87)"
    },
    "h6": {
      "fontSize": 21,
      "fontWeight": 500,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": 1,
      "color": "rgba(0, 0, 0, 0.87)"
    },
    "subtitle1": {
      "fontSize": 16,
      "fontWeight": 400,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": "24px",
      "color": "rgba(0, 0, 0, 0.87)"
    },
    "body1": {
      "fontSize": 14,
      "fontWeight": 500,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": "24px",
      "color": "rgba(0, 0, 0, 0.87)"
    },
    "body2": {
      "fontSize": 14,
      "fontWeight": 300,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": "24px",
      "color": "rgba(0, 0, 0, 0.87)"
    },
    "caption": {
      "fontSize": 12,
      "fontWeight": 400,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "lineHeight": 1,
      "color": "rgba(0, 0, 0, 0.54)"
    },
    "button": {
      "fontSize": 14,
      "textTransform": "uppercase",
      "fontWeight": 400,
      "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      "color": "rgba(255,255,255,1)"
    },
  },
  "shadows": [
    "none",
    "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
  ],
  "transitions": {
    "easing": {
      "easeInOut": "cubic-bezier(0.4, 0, 0.2, 1)",
      "easeOut": "cubic-bezier(0.0, 0, 0.2, 1)",
      "easeIn": "cubic-bezier(0.4, 0, 1, 1)",
      "sharp": "cubic-bezier(0.4, 0, 0.6, 1)"
    },
    "duration": {
      "shortest": 150,
      "shorter": 200,
      "short": 250,
      "standard": 300,
      "complex": 375,
      "enteringScreen": 225,
      "leavingScreen": 195
    }
  },
  "mixins": {},
  "spacing": {
    "unit": 8
  },
  "breakpoints": {
    "keys": [
      "xs",
      "sm",
      "md",
      "lg",
      "xl"
    ],
    "values": [
      320,
      768,
      992,
      1140,
      1200
    ]
  },
  "zIndex": {
    "mobileStepper": 900,
    "menu": 1000,
    "appBar": 1100,
    "drawerOverlay": 1200,
    "navDrawer": 1300,
    "dialogOverlay": 1400,
    "dialog": 1500,
    "layer": 2000,
    "popover": 2100,
    "snackbar": 2900,
    "tooltip": 3000
  },
  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: 'white',
      },
      containedPrimary: {
        color: 'white',
      },
      containedSecondary: {
        color: 'white',
      }
    },
  },
});

const drawerWidth = 240;
const styles = {
  siteTitle: {
    color: theme.palette.common.white,
  },
  headerGroupRight: {
    position: 'relative'
  },
  languageMenu: {
    margin: 0,
    padding: 0,
    top: '100%',
    left: '50%',
    position: 'absolute',
    opacity: 0,
    transformOrigin: 'center top',
    transform: 'translate3d(-50%,0,0)',
    borderRadius: 3,
    background: theme.palette.common.white,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    '&.open': {
      opacity: 1
    }
  },
  footer: {
    background: theme.palette.grey[900]
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflow: 'hidden'
  },
  toolbar: {
    padding: 0
  },
  subToolbar: {
    color: theme.palette.common.white,
  },
  tableToolbar:{
    padding:'0 15px',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  heading: {
    flex:1
  },
  tableHead: {
    color: 'white',
    fontWeight: 500
  },
  dialogMedia: {
    padding: 15
  },
  dialogColor: {
    padding: 15
  },
  dialogForm: {
    padding: 15,
    minWidth: 400
  },
  colorPicker: {
    boxShadow: 'none!important',
    borderRadius: '0!important',
    padding: '0!important',
    width: 'auto!important'
  },
  button: {
    margin: theme.spacing.unit,
    width: 36,
    height:36,
    color: theme.palette.common.white,
    '& > span': {
      pointerEvents: 'none'
    }
  },
  auto_toggle: {
    position:'absolute',
    margin: '20px auto',
    border: 'solid 1px ' + theme.palette.error[500],
    color: '#fff',
    opacity:0,
    top:0,
    left:0,
    right:0,
    width: '50%',
    background: '#fff',
    padding: '10px 15px',
    transform: 'translate3d(0,-200px,0)',
    // transition: 'all 0.3s linear',
    zIndex:1000,
    boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
  },
  run: {
    // transform: 'translate3d(0,0,0)',
    // opacity: 1
    animation: 'DownFade 4s ease-in 1s forwards'
  },
  right:{
      textAlign: 'right'
  },
  hide: {
    display: 'none!important'
  },
  error: {
    color: theme.palette.error[500],
    borderBottom: 'solid 2px '+ theme.palette.error[500]+'!important',
  },
  merncms_editor:{
    border: '1px solid rgba(0, 0, 0, 0.42)',
    boxShadow: '0 0 5px 0px #bbb inset',
    background: '#fff',
    paddingLeft: 15,
    paddingRight: 15,
    minHeight: 300
  },
  merncms_toolbar:{
    border: '1px solid rgba(0, 0, 0, 0.42)',
  },
  label_shrink: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    display: 'block',
    marginTop: 20,
    marginBottom: 6
  },
  folder_view: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0!important'
  },
  folder_view_li: {
    width: '25%',
    cursor: 'pointer'
  },
  folder_view_file_icon_wrapper:{
    display: 'flex',
  },
  folder_view_file_icon: {
    color: '#fff',
    width: 36,
    height: 36,
    padding: 0
  },
  container: {

  },
  form:{
    minWidth: 290,
    margin: '50px auto 0',
    padding:30,
    border: 'solid 1px #eee',
    boxShadow: '0 0 15px rgba(0,0,0,0.1)',
    background: '#fff'
  },
  formFlat:{
    minWidth: 290,
    margin: '0',
    padding:30,
    background: '#fff'
  },
  textField: {
    width: '100%',
  },
  textFieldInline:{
    width: '48%'
  },
  formRow: {
    justifyContent:'space-between',
    marginTop:16,
    marginBottom:8
  },
  formRowToolbar:{
    justifyContent:'space-between',
    marginTop:16,
    marginBottom:8,
    flex:1,
    paddingRight:15
  },
  formRowInline:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: 15,
    marginLeft: -15,
    marginRight: -15
  },
  formRowInlineTop:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  col1: {
    padding: '0 15px',
    flex: 1
  },
  col2: {
    padding: '0 15px',
    flex: 2
  },
  col3: {
    padding: '0 15px',
    flex: 3
  },
  col4: {
    padding: '0 15px',
    flex: 4
  },
  col5: {
    padding: '0 15px',
    flex: 5
  },
  col6: {
    padding: '0 15px',
    flex: 6
  },
  col7: {
    padding: '0 15px',
    flex: 7
  },
  col8: {
    padding: '0 15px',
    flex: 8
  },
  col9: {
    padding: '0 15px',
    flex: 9
  },
  col10: {
    padding: '0 15px',
    flex: 10
  },
  col11: {
    padding: '0 15px',
    flex: 11
  },
  cmd:{
    display: 'flex',
    justifyContent: 'center',
    padding: '15px'
  },
  label: {
    display:'inline-block',
    marginBottom:5,
    position:'relative',
    top:8
  },
  labelCell: {
    fontSize: '14px',
    color : 'rgba(0, 0, 0, 0.87)',
    fontWeight: 300
  },
  select: {
    borderRadius:0,
    appearance: 'listbox',
    border: 'none',
    borderBottom: 'solid 1px ' + theme.palette.input.bottomLine,
    padding: '9px 0',
    background:'none',
    outline: 'none',
    minHeight: 32,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: 16,
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontWeight: 300
  },
  selectOption: {
    fontSize: 16,
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    fontWeight: 300
  },
  selectMultiLevel: {
    display: 'block',
    width: '100%',
    '& option': {
      padding: '5px 15px',
    },
    '& .root': {
      background: theme.palette.grey[300]
    }
  },
  suggestion: {
    borderBottom: 'solid 1px ' + theme.palette.input.bottomLine,
    marginTop:-theme.spacing.unit,
    boxShadow: theme.shadows[1]
  },
  chipRow:{
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection:'row'
  },
  chip:{
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  media:{
    height:200
  },
  thumbnailWrapper: {
    width: 120,
    height: 80,
    overflow: 'hidden',
    margin: '5px 0'
  },
  thumbnail:{
    // height: 60,
    // width: 100,
    maxWidth: '100%',
    margin: '5px 0',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  fluidThumbnail: {
    height: 200,
    alignSelf: 'flex-start',
    '&.small': {
      height: 100
    }
  },
  fixedButton: {
    width: 120
  },
  paging:{
    background: '#fff'
  },
  appBar: {
    background: theme.palette.primary[700],
    color: theme.palette.common.white,
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '& button': {
      color: theme.palette.common.white,
    }
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appButton: {
    color: theme.palette.common.white
  },
  menuButton: {
    marginLeft: -15,
  },
  flex:{
    flex:1
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    overflow: 'hidden',
    margin: '10px auto'
  },
  loadingOverlay: {
    position: 'absolute',
    top:40,
    left:40,
    right:40,
    bottom:40,
    background: 'rgba(255,255,255,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  dialogProgress:{

  },
  progressUpload: {
    width: 250,
  },
  progressLoading: {
    margin: theme.spacing.unit * 2,
  },
  alertMessage: {
    padding: theme.spacing.unit * 2,
    color: theme.palette.error[500]
  },
  folderIcon: {
    background: theme.palette.grey[50],
    color: theme.palette.primary[900]
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  gridListTile: {
    border: 'solid 2px #fff',
    textAlign: 'center',
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  tableExcelToolbar: {
    padding: '15px',
    justifyContent: 'flex-end',
    display: 'flex',
    '& button': {
      marginRight: 15
    }
  },
  tableResponsive: {
    overflow: 'hidden',
    '& th,& td': {
      padding: '0 15px'
    },
    '& td:last-child': {
      paddingRight: 15,
      whiteSpace: 'nowrap',
      '& button': {
        width: '30px',
        height: '30px',
        margin: '0 4px',
        padding: 0,
        color: theme.palette.common.lightBlack
      }
    },
    '& td:last-child button[disabled]': {
      color: theme.palette.grey[100]
    },
    '& .draggable > td:first-child': {
      paddingLeft: 30,
      verticalAlign: 'middle',
      boxShadow: '2px 0 0 0 inset ' + theme.palette.grey[100],
      '&:hover': {
        boxShadow: '4px 0 0 0 inset ' + theme.palette.grey[100],
      }
    }
  },
  tablePaging: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      margin: '0 10px'
    }
  },
  tableSelect: {
    color: theme.palette.common.lightBlack,
    fontSize: '0.75rem'
  },
  cellCommand: {
    textAlign: 'right'
  },
  cellLoading: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    position: 'relative',
    '& $loadingSpinner': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  tableLoading: {
    height: 'auto',
    minHeight: 50,
    position: 'relative',
    cursor: 'not-allowed',
    pointerEvents: 'none',
    '& $loadingSpinner': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  loadingSpinner: {
    position: 'absolute',
    display: 'none',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    background: theme.palette.common.darkWhite,
    textAlign: 'center',
  },
  errorMessage: {
    background: theme.palette.error[900],
    color: theme.palette.common.white,
    margin: 10,
    borderRadius: 3
  },
  messageRow: {
    background: theme.palette.error[600],
    color: theme.palette.common.white,
    margin: '10px 15px',
    borderRadius: 3,
    fontWeight: 700
  },
  messageIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 10
  },
  drag_item: {
    cursor: 'move',
    transition: 'all .1s linear'
  },
  formImageControl: {
    position: 'relative',
    padding: '0 15px',
    flex: 1
  },
  packageItemWrapper: {
    position: 'relative',
    marginBottom: 15,
    padding: '0 15px',
    '& button': {
      alignSelf: 'flex-end',
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  packageItem: {
    position: 'relative',
    padding: '0 15px',
    background: '#f0f0f0'
  },
  packageItemDelete: {
    position: 'absolute',
    right: 10,
    top: 25
  },
  [theme.breakpoints.up(1)]: {
    minForm: {
      width: '75%',
      maxWidth: 600
    },
  }
};

export {styles};
export default theme;
