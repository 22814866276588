import React, { Component } from 'react';
// import injectTapEventPlugin from './plugins/TapEventPlugin';
import { MuiThemeProvider } from '@material-ui/core/styles';

import './assets/scss/main.scss';
import theme from './layout/theme';
import Main from './layout/main.jsx';
import Header from './layout/header.jsx';
import ReactPressMenu from './layout/menu.jsx';

// injectTapEventPlugin();

class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      openMenu: true,
      title: 'ReactPress v1.0.0',
      width: window.innerWidth,
      user: (localStorage.getItem('user') !== undefined ? JSON.parse(localStorage.getItem('user')) : {}),
      isLoggedIn: (localStorage.getItem('user_token') !== undefined && localStorage.getItem('user_token') !== null),
    };
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth});
  }
  componentDidMount() {
    let $this  = this;

    if (window.innerWidth < 1024) {
      $this.setState({
        openMenu : false,
      },function(){
        $this.updateDimensions();
      });
    } else {
      $this.updateDimensions();
    }

    window.addEventListener("resize", $this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  toggleMenu() {
    this.setState({openMenu: !this.state.openMenu});
  }
  handleClose() {
    this.setState({openMenu: false});
  }
  updateTilte(text) {
    this.setState({title: text});
  }
  loggedIn() {
    let logged = (localStorage.getItem('user_token') !== undefined && localStorage.getItem('user_token') !== null);
    this.setState({isLoggedIn:logged, user:JSON.parse(localStorage.getItem('user'))});
  }
  render() {
    const $this = this;

    return (
      <MuiThemeProvider theme={theme}>
        <div className="merncms">
          <Header language={$this.state.language} screenWidth={$this.state.width} user={$this.state.user} open={$this.state.openMenu} title={$this.state.title} toggleMenu={()=>this.toggleMenu()} isLoggedIn={$this.state.isLoggedIn} updateLoggedStatus={()=>this.loggedIn()}/>
          <div className="merncms-main-body">
            <ReactPressMenu language={$this.state.language} screenWidth={$this.state.width} open={$this.state.openMenu} theme={theme} closeMenu={()=>this.handleClose()} setTitle={(text)=>this.updateTilte(text)} isLoggedIn={$this.state.isLoggedIn} user={$this.state.user}/>
            <Main language={$this.state.language} screenWidth={$this.state.width} open={$this.state.openMenu} isLoggedIn={$this.state.isLoggedIn} updateLoggedStatus={()=>this.loggedIn()} user={$this.state.user}/>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;
