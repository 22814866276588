const WebConfig = {
  development: {
    api: 'http://reactpress.local:3102',
    image: 'http://reactpress.local:3102/data/images/',
    // api: 'https://codeapi.bacs.vn',
    // image: 'https://codeapi.bacs.vn/data/upload/images/',
    itemPerPage: 50,
  },
  staging: {
    api: 'https://codeapi.bacs.vn',
    image: 'https://codeapi.bacs.vn/data/images/',
    itemPerPage: 50,
  },
  production: {
    api: 'https://codeapi.bacs.vn',
    image: 'https://codeapi.bacs.vn/data/upload/images/',
    itemPerPage: 50,
  }
};

export default WebConfig;