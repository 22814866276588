import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Utils from '../api/api';
import theme from './theme';
import CircularProgress from '@material-ui/core/CircularProgress';

import WebConfig from '../api/config';

import Loadable from 'react-loadable';

let config = WebConfig.production;
if (window.location.hostname.indexOf('local')!==-1) {
  config = WebConfig.development;
}

const Loading = () => <div className='main-loading-overlay'><CircularProgress></CircularProgress></div>;

const Login = Loadable({
  loader: () => import('../components/login'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('../components/dashboard'),
  loading: Loading,
});

const Banner = Loadable({
  loader: () => import('../components/banner'),
  loading: Loading,
});

const Bill = Loadable({
  loader: () => import('../components/bill'),
  loading: Loading,
});

const Account = Loadable({
  loader: () => import('../components/account'),
  loading: Loading,
});

const ReactPressProperty = Loadable({
  loader: () => import('../components/property'),
  loading: Loading,
});

const Product = Loadable({
  loader: () => import('../components/product'),
  loading: Loading,
});

const Course = Loadable({
  loader: () => import('../components/course'),
  loading: Loading,
});

const CourseOnline = Loadable({
  loader: () => import('../components/course_online'),
  loading: Loading,
});

const Category = Loadable({
  loader: () => import('../components/category_product'),
  loading: Loading,
});

const CategoryCourseOnline = Loadable({
  loader: () => import('../components/category_course_online'),
  loading: Loading,
});

const CategoryCourseOffline = Loadable({
  loader: () => import('../components/category_course_offline'),
  loading: Loading,
});

const FAQs = Loadable({
  loader: () => import('../components/faq'),
  loading: Loading,
});

const News = Loadable({
  loader: () => import('../components/news'),
  loading: Loading,
});

const Hiring = Loadable({
  loader: () => import('../components/hiring'),
  loading: Loading,
});

const CategoryHiring = Loadable({
  loader: () => import('../components/category_hiring'),
  loading: Loading,
});

const Company = Loadable({
  loader: () => import('../components/company'),
  loading: Loading,
});

const Candidate = Loadable({
  loader: () => import('../components/candidate'),
  loading: Loading,
});

const ContentPage = Loadable({
  loader: () => import('../components/content_page'),
  loading: Loading,
});

const Block = Loadable({
  loader: () => import('../components/content_block'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('../components/users'),
  loading: Loading,
});

const Group = Loadable({
  loader: () => import('../components/user_groups'),
  loading: Loading,
});

const MediaLibrary = Loadable({
  loader: () => import('../components/media'),
  loading: Loading,
});

const WebMenu = Loadable({
  loader: () => import('../components/web_menu'),
  loading: Loading,
});

const Service = Loadable({
  loader: () => import('../components/service'),
  loading: Loading,
});

const Album = Loadable({
  loader: () => import('../components/album'),
  loading: Loading,
});

const Testimonial = Loadable({
  loader: () => import('../components/testimonial'),
  loading: Loading,
});

const CategoryNews = Loadable({
  loader: () => import('../components/category_news'),
  loading: Loading,
});

const Settings = Loadable({
  loader: () => import('../components/settings'),
  loading: Loading,
});

const Permission = Loadable({
  loader: () => import('../components/permission'),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import('../components/change_password'),
  loading: Loading,
});

const ForgotPassword = Loadable({
  loader: () => import('../components/forgot_password'),
  loading: Loading,
});

const ResetPassword = Loadable({
  loader: () => import('../components/reset_password'),
  loading: Loading,
});

const SubscribesList = Loadable({
  loader: () => import('../components/subscribe'),
  loading: Loading,
});

const Campaign = Loadable({
  loader: () => import('../components/campaign'),
  loading: Loading,
});

const ReactPressFile = Loadable({
  loader: () => import('../components/files'),
  loading: Loading,
});

const Schedule = Loadable({
  loader: () => import('../components/schedule'),
  loading: Loading,
});

const Lecturer = Loadable({
  loader: () => import('../components/lecturer'),
  loading: Loading,
});

const CategoryFAQ = Loadable({
  loader: () => import('../components/category_faq'),
  loading: Loading,
});

const Booking = Loadable({
  loader: () => import('../components/booking'),
  loading: Loading,
});

const CategoryBlog = Loadable({
  loader: () => import('../components/category_blog'),
  loading: Loading,
});

const Blog = Loadable({
  loader: () => import('../components/blogs'),
  loading: Loading,
});

const Project = Loadable({
  loader: () => import('../components/project'),
  loading: Loading,
});


const drawerWidth = 240;
const styles = {
  content: {
    width: 'calc(100% - 240px)',
    overflowX:'hidden',
    marginLeft: -drawerWidth,
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: 'calc(100% - 56px)',
    marginTop: 56,
    position:'relative'
  },
  [theme.breakpoints.up('sm')]: {
    content: {
      height: 'calc(100% - 64px)',
      marginTop: 64,
      padding: theme.spacing.unit * 3,
    },
  },
  contentShift: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
};


class Main extends Component {
  constructor(props){
    super(props);
    this.state = {
      group: '',
      group_id: '',
      groups: [],
      permissions: [],
      status: 0
    }
  }
  componentDidMount(){

    let $this = this;
    if(!$this.props.isLoggedIn && $this.context.router.route.location.pathname.indexOf('/login') === -1) {
      //Install
      $this.context.router.history.push('/login');
    }else{
      let u = $this.props.user || {_id: 0};
      
      Utils.getSingleData('users', u._id, res => {
        if (res.status === 'success' && res.results !== null) {
          let g = JSON.parse(localStorage.getItem('userg'));
          Utils.getListData('usergroup', 1, config.itemPerPage, '0,0', null, 1, function(data){
            $this.setState({groups:data.results},function(){
              if(g!==null){
                g = Utils.atou(g.group);
                let gn = this.state.groups.filter(x=>{
                  return x._id === g;
                });
                this.setState({group:gn[0].name,group_id:g},function(){
                  if($this.state.group.toLowerCase()!=='administrator') {
                    Utils.getSingleData('permission_by_group',g,function(data){
                      if(data.status==='success') {
                        $this.setState({permissions:data.results[0].permissions,status:1});
                      }
                    });
                  }
                });
              }
            });
          });
        } else {
          //Install
          if (u._id !== 0) {
            Utils.logout(u.username, (data) => {
              if(data.message==='success'){
                localStorage.removeItem('user_token');
                localStorage.removeItem('user');
                localStorage.removeItem('userg');
                //console.log($this.context.router);
                $this.props.updateLoggedStatus();
                $this.context.router.history.push('/login');
              }else{
                alert(data.message);
              }
            });
          }
        }
      });
    }
  }
  updateStatusLogin = (cb) => {
    try {
      var g = JSON.parse(localStorage.getItem('userg'));
      if(g!==null){
        g = Utils.atou(g.group);
        var gn = this.state.groups.filter(x=>{
          return x._id === g;
        });
        if(gn.length>0){
          this.setState({group:gn[0].name},function(){
            //console.log($this.state.group);
            this.props.updateLoggedStatus();
            cb();
          });
        }else{
          this.props.updateLoggedStatus();
          cb();
        }
      }
      //console.log(cb);

    } catch (error) {
      console.log(error);
    }
  }
  isHavePermission = () => {
    //console.log('run isHavePermission');
    try {
      if(this.state.status===0) {
        return true;
      } else {
        let c = this.context.router.route.location.pathname.split('/')[1];
        if(this.state.permissions.indexOf(c)!==-1) {
          //console.log('OK');
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      //console.log(error);
      return false;
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={'main ' + classNames(classes.content, (this.props.open) && classes.contentShift)}>
        <Switch>
          <Route exact path='/' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:dashboard" />) : (<Dashboard/>))}/>
          <Route path='/dashboard' render={() => (
            !this.props.isLoggedIn ? (<Redirect to="/login/:dashboard" />) : (<Dashboard/>))}/>
          <Route exact path='/media/:folder?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<MediaLibrary/>))}/>
          <Route path='/login/:url?' render={()=> (<Login isLoggedIn={this.props.isLoggedIn} updateStatus={(cb)=>this.updateStatusLogin(cb)}/>)} />
          <Route path='/change-password' render={()=>(<ChangePassword/>)}/>
          <Route path='/forgot-password' render={()=>(<ForgotPassword/>)}/>
          <Route path='/reset-password/:token' render={()=>(<ResetPassword/>)}/>
          <Route path='/bill/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Bill/>))} />
          <Route path='/account/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Account/>))}/>
          <Route path='/property/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<ReactPressProperty/>))}/>
          <Route path='/product/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Product/>))}/>
          <Route path='/course-offline/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Course/>))}/>
          <Route path='/course-online/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<CourseOnline/>))}/>
          <Route path='/category/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Category/>))}/>
          <Route path='/blogcat/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<CategoryBlog/>))}/>
          <Route path='/course-online-category/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<CategoryCourseOnline/>))}/>
          <Route path='/course-offline-category/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<CategoryCourseOffline/>))}/>
          <Route path='/banner/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Banner/>))}/>
          <Route path='/menu/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<WebMenu/>))}/>
          <Route path='/news/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<News/>))}/>
          <Route path='/blog/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Blog/>))}/>
          <Route path='/projects/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Project/>))}/>
          <Route path='/hiring/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Hiring/>))}/>
          <Route path='/career/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<CategoryHiring/>))}/>
          <Route path='/company/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Company/>))}/>
          <Route path='/candidate/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Candidate/>))}/>
          <Route path='/pages/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<ContentPage/>))}/>
          <Route path='/shareblocks/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Block/>))}/>
          <Route path='/faq/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<FAQs/>))}/>
          <Route path='/users/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Users group={this.state.group}/>))}/>
          <Route path='/group/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Group group={this.state.group}/>))}/>
          <Route path='/service/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Service/>))}/>
          <Route path='/album/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Album/>))}/>
          <Route path='/testimonial/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Testimonial/>))}/>
          <Route path='/newscat/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<CategoryNews/>))}/>
          <Route path='/setting/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Settings/>))}/>
          <Route path='/permission/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Permission group={this.state.group}/>))}/>
          <Route path='/subscribers/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<SubscribesList/>))}/>
          <Route path='/campaign/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Campaign/>))}/>
          <Route path='/document/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<ReactPressFile/>))}/>
          <Route path='/calendar/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Schedule/>))}/>
          <Route path='/lecturers/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Lecturer/>))}/>
          <Route path='/faqgroup/:action?/:id?' render={() => (
            !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<CategoryFAQ/>))}/>
          <Route path='/booking/:action?/:id?' render={() => (
          !this.isHavePermission() ? (<Redirect to="/dashboard/:denied" />) : (<Booking/>))}/>
        </Switch>
      </div>
    );
  }
}
Main.propTypes = {
  classes: PropTypes.object.isRequired,
  //router: PropTypes.object
};
Main.contextTypes = {
  router: PropTypes.object
};
export default withStyles(styles)(Main);
