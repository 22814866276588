import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar,Toolbar, IconButton, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import ExitIcon from '@material-ui/icons/ExitToApp';
// import LanguageIcon from '@material-ui/icons/Language';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';

import theme, {styles} from '../layout/theme';
import Utils from '../api/api';

import language_setting from '../assets/languages/setting.json';

class Header extends Component {
  constructor(props){
    super(props);
    this.state = {
      width:  960,
    };
  }
  handleToggle() {
    this.props.toggleMenu();
  }
  handleLogout() {
    //console.log(this.state);
    var $this = this;
    Utils.logout(this.state.username,function(data){
      //console.log(data);
      if(data.message==='success'){
        localStorage.removeItem('user_token');
        localStorage.removeItem('user');
        localStorage.removeItem('userg');
        //console.log($this.context.router);
        $this.props.updateLoggedStatus();
        $this.context.router.history.push('/login');
      }else{
        alert(data.message);
      }
    });
  }
  // handleToggleLanguage(e) {
  //   let $this = this;
  //   $this.setState({
  //     openLanguage: !$this.state.openLanguage
  //   });
  // }
  // handleChangeLanguage(language) {
  //   let $this = this;
  //   $this.setState({
  //     openLanguage: !$this.state.openLanguage
  //   });
  // }
  componentDidMount(){
    var lang = [];
    language_setting.languages.map(x=>{
      lang.push({
        language:x.split(':')[0],
        title:x.split(':')[1]
      });
    });

    this.setState({
      languages: lang
    });

  }
  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({
      languageData: nextProps.language
    });
  }
  render(){
    const { classes } = this.props;
    const $this = this;
    //var type = this.props.screenWidth<960 ? false: true;
    // var dataLang = $this.state.languageData;
    // if($this.state.languageData.ReactPress!==undefined){
    //   dataLang = $this.state.languageData.ReactPress.Content;
    // }


    return(
      <AppBar className={'header ' + classNames(classes.appBar, ((this.props.open)&&this.props.isLoggedIn)  && classes.appBarShift)}>
        <Toolbar>
          <IconButton aria-label="Menu" onClick={()=>this.handleToggle()} color="inherit" className={classNames(classes.menuButton, (this.props.open)  && classes.hide)}>
            <MenuIcon/>
          </IconButton>
          <Typography className={classNames(classes.flex,classes.siteTitle)}>{this.props.title}</Typography>
          {
            this.props.isLoggedIn && <Typography variant="caption" color="inherit" className={classes.flex}>Xin chào, <Link style={{color:theme.palette.common.white}} to={'/change-password/'} >{this.props.user.title}</Link></Typography>
          }
          <div className={classes.headerGroupRight}>
            {/* <IconButton  aria-label="Change Language" onClick={(e)=>this.handleToggleLanguage(e)} className={classes.appButton}>
              <LanguageIcon/>
            </IconButton> */}
            <IconButton aria-label="LogOut" onClick={()=>this.handleLogout()} className={classNames(classes.appButton, !this.props.isLoggedIn  && classes.hide)}>
              <ExitIcon/>
            </IconButton>
            {/* <ul className={classNames(classes.languageMenu,$this.state.openLanguage?'open':'')}>
              {
                $this.state.languages.map((x,i) => {
                  return (
                    <MenuItem key={i} onClick={(e)=>$this.handleChangeLanguage(x.language)}>{x['title']}</MenuItem>
                  )
                })
              }
            </ul> */}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}


Header.propTypes = {
  classes: PropTypes.object.isRequired,
};
Header.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(Header);
